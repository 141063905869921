import {TRANSLATION_SERVICE_KEY, TranslationService} from "./translation/translation-service";
import {CUSTOMER_GROUP_SERVICE_KEY, CustomerGroupService} from "./group/customer-group";
import {EMPLOYEE_SERVICE_KEY, EmployeeService} from "./employee/employee-service";
import {CUSTOMER_SERVICE_KEY, CustomerService} from "./customer/customer-service";
import {COUNTRY_SERVICE_KEY, CountryService} from "./country/country-service";
import {COMPETITION_SERVICE_KEY, CompetitionService} from "./competition/competition-service";
import {FORM_SERVICE_KEY, FormService} from "./form/form-service";

if (!window[CUSTOMER_GROUP_SERVICE_KEY]) {
  window[CUSTOMER_GROUP_SERVICE_KEY] = new CustomerGroupService();
}

if (!window[EMPLOYEE_SERVICE_KEY]) {
  window[EMPLOYEE_SERVICE_KEY] = new EmployeeService();
}

if (!window[CUSTOMER_SERVICE_KEY]) {
  window[CUSTOMER_SERVICE_KEY] = new CustomerService();
}

if (!window[COUNTRY_SERVICE_KEY]) {
  window[COUNTRY_SERVICE_KEY] = new CountryService();
}

if (!window[TRANSLATION_SERVICE_KEY]) {
  window[TRANSLATION_SERVICE_KEY] = new TranslationService();
}

if (!window[COMPETITION_SERVICE_KEY]) {
  window[COMPETITION_SERVICE_KEY] = new CompetitionService();
}

if (!window[FORM_SERVICE_KEY]) {
  window[FORM_SERVICE_KEY] = new FormService();
}

