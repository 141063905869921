export class Referral {
  public number: string;
  public token: string;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(values) {
    this.number = values.number;
    this.token = values.token;
    this.createdAt = values.createdAt;
    this.updatedAt = values.updatedAt;
  }
}
