import {sendContactForm} from "./contact";

export const FORM_SERVICE_KEY = 'NY_FORM_SERVICE';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    NY_FORM_SERVICE: IFormService;
  }
}

interface IFormService {
  sendContactForm(formData: FormData): Promise<boolean>
}

export function getFormService(): IFormService {
  const fs = window[FORM_SERVICE_KEY];
  if (fs) {
    return fs as IFormService;
  }
  return null;
}

export class FormService implements IFormService {
  async sendContactForm(formData: FormData): Promise<boolean> {
    return sendContactForm(formData);
  };
}
