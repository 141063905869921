import config from "../../config";
import {Referral} from "./models";

export const EMPLOYEE_SERVICE_KEY = 'NY_EMPLOYEE_SERVICE';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    NY_EMPLOYEE_SERVICE: IEmployeeService;
  }
}

export interface IEmployeeService {
  registerReferral (firstName: string, lastName: string, number: string): Promise<Referral>;
  referralCollect (token: string, customerId: string);
}

export function getEmployeeService(): IEmployeeService {
  const es = window[EMPLOYEE_SERVICE_KEY];
  if (es) {
    return es as IEmployeeService;
  }
  return null
}

export class EmployeeService implements IEmployeeService{

  async registerReferral (firstName: string, lastName: string, employeeNumber: string): Promise<Referral> {
    const response = await fetch(`${config.customerServiceUrl}/employee/referral/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        number: employeeNumber
      })
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error("could not register referral: " + error.message);
    }

    return new Referral(await response.json());
  }

  async referralCollect (token: string, customerId: string) {
    const response = await fetch(`${config.customerServiceUrl}/employee/referral/collect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: token,
        profileId: customerId,
      })
    });
    if (!response.ok) {
      const data = await response.json();
      throw new Error("could not collect referral: " + data.message);
    }
  }
}
