import config from "../../config";

export async function sendContactForm(formData: FormData): Promise<boolean> {
  const result = await fetch(`${config.formServiceUrl}/contact-form`, {
    method: 'POST',
    cache: 'no-cache',
    body: formData,
  });

  if (!result.ok) {
    throw new Error("error sending contact-form: " + result.statusText);
  }

  return true;
}
